<template>
  <div class="container">
      <router-view />
  </div>
</template>

<script>
export default {
  components: {

  },
  computed:{},
  data() {
    return {

    }
  },
  watch:{},
  created(){},
  mounted(){},
}
</script>

<style scoped lang="scss">

</style>
