<template>
	<router-view />
</template>

<script>
export default {
	created() {
		let url_data = this.getUrlParams(window.location.href);
		if (url_data.key) {
			localStorage.store_key = url_data.key;
		}
		if (url_data.data) {
			localStorage.token = url_data.data;
		}
	},
	// mounted() {
	// this.$router.replace({
	// 	path: window.location.href.split('?')[0].split('#')[1],
	// });
	// },
	methods: {
		getUrlParams(url) {
			let vars = {};
			url.replace(/[?&]+([^=&]+)=([^&#]*)/gi, function (m, key, value) {
				vars[key] = value;
			});
			return vars;
		},
	},
};
</script>
<style lang="scss">
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
.buttonColor {
	background-color: #197aff !important;
}
.el-input__wrapper {
	height: 36px;
}
</style>
