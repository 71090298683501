import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '@/style/index.scss'
import Bus from './utils/bus.js'/// mitt 总线程引入
import { request } from './utils/request'
let app = createApp(App)
app.config.globalProperties.$bus = Bus;
app.config.globalProperties.$request = request;
// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component)
}
import zhcn from 'element-plus/es/locale/lang/zh-cn'
app.use(store)
app.use(router)
app.use(ElementPlus, {
	locale: zhcn,
})
app.mount('#app')
