import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/Layout/index.vue'
const routes = [
	{ path: '/', redirect: '/setting/grade' },
	// 初始化设置
	{
		path: '/init',
		name: 'init',
		component: () => import('../views/initialization/index.vue'),
		meta: { title: '初始化设置' },
		children: [
			{
				path: '/init/template',
				name: 'template',
				component: () => import('../views/initialization/templateStyle.vue')
			},
			{
				path: '/init/text',
				name: 'text',
				component: () => import('../views/initialization/initialValue.vue')
			},
			{
				path: '/init/introduction',
				name: 'introduction',
				component: () => import('../views/initialization/introduction.vue')
			},
			{
				path: '/init/member',
				name: 'member',
				component: () => import('../views/initialization/member.vue')
			},
			{
				path: '/init/payment',
				name: 'payment',
				component: () => import('../views/initialization/payment.vue')
			},
		]
	},
	{
		path: '/init/attention',
		name: 'attention',
		component: () => import('../views/initialization/attention.vue')
	},
	{
		path: '/init/accomplish',
		name: 'accomplish',
		component: () => import('../views/initialization/accomplish.vue')
	},
	// 全局设置
	{
		path: '/setting',
		name: 'setting',
		component: () => import('../views/globalSetting/index.vue'),
		meta: { title: '系统设置' },
		children: [
			{
				path: '/setting/grade',
				name: 'grade',
				component: () => import('../views/globalSetting/memberManagement/gradeMembership.vue'),
				meta: { title: '会员等级' }
			},
			{
				path: '/setting/privileges',
				name: 'privileges',
				component: () => import('../views/globalSetting/memberManagement/membershipPrivileges.vue'),
				meta: { title: '会员权限' }
			},
			{
				path: '/setting/structure',
				name: 'structure',
				component: () => import('../views/globalSetting/memberManagement/leadershipStructure.vue'),
				meta: { title: '领导架构' }
			},
			{
				path: '/setting/joining',
				name: 'joining',
				component: () => import('../views/globalSetting/memberManagement/joining.vue'),
				meta: { title: '入会流程' }
			},
			{
				path: '/setting/remind',
				name: 'remind',
				component: () => import('../views/globalSetting/memberManagement/memberRemind.vue'),
				meta: { title: '会员提醒' }
			},
			{
				path: '/setting/field',
				name: 'field',
				component: () => import('../views/globalSetting/memberManagement/memberField.vue'),
				meta: { title: '会员字段' }
			},
			{
				path: '/setting/certificate',
				name: 'certificate',
				component: () => import('../views/globalSetting/memberManagement/electronicCertificate.vue'),
				meta: { title: '电子证书' }
			},
			{
				path: '/setting/signature',
				name: 'signature',
				component: () => import('../views/globalSetting/mass/signature.vue'),
				meta: { title: '签名设置' }
			},
			{
				path: '/setting/mail',
				name: 'mail',
				component: () => import('../views/globalSetting/mass/mail.vue'),
				meta: { title: '邮件设置' }
			},
			{
				path: '/setting/industry',
				name: 'industry',
				component: () => import('../views/globalSetting/classify/industryCategory.vue'),
				meta: { title: '行业分类' }
			},
			{
				path: '/setting/website',
				name: 'website',
				component: () => import('../views/globalSetting/classify/website.vue'),
				meta: { title: '网站菜单' }
			},
			{
				path: '/setting/label',
				name: 'label',
				component: () => import('../views/globalSetting/memberManagement/buyerLabel.vue'),
				meta: { title: '会员标签' }
			},
			{
				path: '/setting/specification',
				name: 'specification',
				component: () => import('../views/globalSetting/classify/specification.vue'),
				meta: { title: '规格管理' }
			},
			{
				path: '/setting/payments',
				name: 'payments',
				component: () => import('../views/globalSetting/payments.vue'),
				meta: { title: '支付设置' }
			},
			{
				path: '/setting/weChatSet',
				name: 'weChatSet',
				component: () => import('../views/globalSetting/weChatSet.vue'),
				meta: { title: '微信设置' }
			},
			{
				path: '/setting/dns',
				name: 'dns',
				component: () => import('../views/globalSetting/dnsSetup.vue'),
				meta: { title: '域名设置' }
			},
			{
				path: '/setting/log',
				name: 'log',
				component: () => import('../views/globalSetting/systemLog.vue'),
				meta: { title: '系统日志' }
			},
		]
	},
	// 后台页面
	{
		path: '/certified',
		name: 'certified',
		component: Layout,
		meta: { title: '认证会员' },
		children: [
			{
				path: '/certified/details',
				name: 'details',
				component: () => import('@/views/backstage/certified/details.vue'),
				meta: { title: '详情' }
			},
			{
				path: '/certified/edit',
				name: 'edit',
				component: () => import('@/views/backstage/certified/edit.vue'),
				meta: { title: '编辑' }
			},
		],
	},
	{
		path: '/thinkTank',
		name: 'thinkTank',
		component: import('@/views/backstage/thinkTank.vue'),
		meta: { title: '专家智库详情' },
	},

	{
		path: '/registered',
		name: 'registered',
		component: import('@/views/backstage/registered.vue'),
		meta: { title: '注册会员详情' },
	},
	{
		path: '/revenue',
		name: 'revenue',
		component: import('@/views/backstage/revenue.vue'),
		meta: { title: '企业营收' },
	},
	{
		path: '/branch',
		name: 'branch',
		component: import('@/views/backstage/branch.vue'),
		meta: { title: '分会管理详情' },
	},


]

const router = createRouter({
	history: createWebHashHistory(),
	routes
})
router.beforeEach((to, from, next) => {
	// if (to.path.split('/')[1] == 'setting') {
	// 	document.title = to.meta.title || '系统设置'
	// }
	next()
});
export default router
